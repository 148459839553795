<template>
  <div>
    <!-- loading????????? -->
    <Loader />

    <div class="wrapper gradient">
      <!-- Sidebar  -->
      <SideBarStyle1 :items="verticalMenu" :logo="logo" @toggle="sidebarMini" />

      <!-- TOP Nav Bar -->
      <NavBarStyle1
        title="Dashboard"
        :homeURL="{ name: 'dashboard1.home' }"
        @toggle="sidebarMini"
        :logo="logo"
      >
      </NavBarStyle1>

      <!-- Right Nav Bar--Chat聊天列表 -->
      <RightSideBar
        toggleClass="top-50 setting-toggle iq-card"
      >
        <i class="ri-sound-module-fill font-size-18 text-primary" slot="icon" />
      </RightSideBar>
      <!-- 主题内容 -->
      <div id="content-page">
        <div :class="includesRouteName">
          <!-- <transition name="router-anim" mode="out-in"> -->
          <router-view />
          <!-- </transition> -->
        </div>
      </div>
    </div>

    <!-- footer -->
    <!-- <FooterStyle1></FooterStyle1> -->
  </div>
</template>
<script>
  import Loader from '../components/socialvue/loader/Loader'
  import SideBarStyle1 from '../components/socialvue/sidebars/SideBarStyle1'
  import NavBarStyle1 from '../components/socialvue/navbars/NavBarStyle1'
  import SideBarItems from '../FackApi/json/SideBar'
  import profile from '../assets/images/user/user-1.jpeg'
  import loader from '../assets/images/logo-1.png'
  import RightSideBar from '../components/socialvue/rightsidebar/RightSideBar'
  import FooterStyle1 from '../components/socialvue/footer/FooterStyle1'
  import { socialvue } from '../config/pluginInit'
  import { Users } from '../FackApi/api/chat'
  import { chatWsUrl } from '../global'
  import { mapActions, mapMutations, mapGetters } from 'vuex'
  export default {
    name: 'Layout1',
    components: {
      Loader,
      SideBarStyle1,
      NavBarStyle1,
      RightSideBar,
      FooterStyle1
    },
    created () {
      this.initWs()
    },
    mounted () {
      this.logo = loader
      this.getUserInfo()
    },
    computed: {
      ...mapGetters({
        dataChat: 'ChatRoom/getDataChat'
      }),

      currentRouteName () {
        return this.$route.name
      },

      includesRouteName () {
        // 拥有fullBanner的页面，沾满屏幕，所以要删除所有类名
        let routeBlacklist = [
          'social.group',
          'social.my-group',
          'social.course',
          'social.course-detail',
          'social.my-course',
          'social.my-certificate',
          'social.meeting',
          'social.my-meeting',
          'social.meeting-details',
          'social.event',
          'social.live-now',
          'social.award',
          'social.my-award',
          'social.award-detail',
          'social.award-certificate',
          'social.event-detail',
          'social.my-event',
          'social.webstore',

          'social.coc-project',
          'social.partner-project',
          'social.jci-board-members-event',
          'social.jci-board-members',
          'social.attendees-project',
          'social.program-project',
          'social.my-schedule',
          /// ////////////// event ///////////
          'social.attendees-event',
          'social.coc-event',
          'social.my-schedule-event',
          'social.partner-event',
          'social.program-event',
          /// ///////////////////
          'social.project',
          'social.projects',
          'social.my-projects',
          'social.my-project',
          'social.problem-list',
          'social.problem-detail',
          'social.project-detail',
          'social.donation',
          'social.jci-organizations',
          'social.senator',
          'social.PackagePrice',
          'social.library',
          'social.skill'
        ]

        // 1200版心
        let routerList = [
          'social.home',
          'social.webstore',
          'social.webstoreallgoods',
          'social.webstoreallshop',
          'social.webstorecart',
          'social.webstoregoodsdetails',
          'social.webstoremyorder',
          'social.webstoreshippingaddress',
          'social.webstoreshop',
          'social.ordermail',
          'social.orderown',
          'social.orderownself',
          'social.placeorder',
          'social.myorder',
          'social.ordertopay',
          'social.ordertodelivered',
          'social.ordertoreceived',
          'social.ordertoevaluated',
          'social.orderrefund',
          'social.orderrefunddetails1',
          'social.orderrefunddetails2',
          'social.orderrefunddetails3',
          'social.pointsmerchandise',
          'social.shopscoredetails',
          'social.scoreplaceorder',
          'social.myexchange',
          'social.scoreorderdetails',
          'social.storeaddress1',
          'social.plan-to-buy'
        ]
        return routeBlacklist.includes(this.$route.name)
          ? ''
          : routerList.includes(this.$route.name)
          ? 'container1200'
          : 'container content-page position-relative'
      }
    },

    watch: {},
    // sidebarTicket
    data () {
      return {
        limitConnect: 3,
        timeConnect: 0,
        timer: null,
        errorNum: 1,
        url: '',
        ws: '', // websocket 实例
        chatWsUrl,
        userInfo: {},
        animated: {
          enter: 'fadeInUp',
          exit: 'slideOutLeft'
        },
        animateClass: [
          {
            value: {
              enter: 'zoomIn',
              exit: 'zoomOut'
            },
            text: 'Zoom'
          },
          {
            value: {
              enter: 'fadeInUp',
              exit: 'fadeOutDown'
            },
            text: 'Fade'
          },
          {
            value: {
              enter: 'slideInLeft',
              exit: 'slideOutRight'
            },
            text: 'Slide'
          },
          {
            value: {
              enter: 'rotateInDownLeft',
              exit: 'rotateOutDownLeft'
            },
            text: 'Roll'
          }
        ],
        verticalMenu: SideBarItems,
        userProfile: profile,
        logo: loader,
        isHeartbeat: true
      }
    },
    methods: {
      ...mapActions({
        langChangeState: 'Setting/setLangAction',
        getUserInfo: 'Public/getUserInfo'
      }),
      ...mapMutations({
        CHANGE_DATA_CHAT: 'ChatRoom/CHANGE_DATA_CHAT'
      }),
      sidebarMini () {
        socialvue.triggerSet()
      },
      logout () {
        localStorage.removeItem('user')
        localStorage.removeItem('access_token')
        this.$router.push({
          name: 'auth1.sign-in1'
        })

      
        // this.ws.close()
      },
      langChange (lang) {
        this.langChangeState(lang)
        this.$i18n.locale = lang.value
        document
          .getElementsByClassName('iq-show')[0]
          .classList.remove('iq-show')
      },
      //   // 初始化websocket
      async initWs () {
        let _this = this
        // 判断当前浏览器是否支持 websocket
        if (typeof WebSocket === 'undefined') {
          alert('您的浏览器不支持socket')
        } else {
          // 实例化WebSocket  并添加全局
          // this.ws = new WebSocket("ws://chat.summer.kim:2346/?Authorization=${token}");
          // let token = _this.$getlocalStorage("token");
          // this.ws = new WebSocket(`ws://chat.chu7ian.icu:2346`);
          // this.ws = new WebSocket(`ws://${chatWsUrl}:2346`);
          
          this.ws = new WebSocket(chatWsUrl)
          // 服务端响应信息
          this.ws.onmessage = await function (e) {
            const res = JSON.parse(e.data)
            // 初始化成功进行绑定
            if (res.type == 'bind') {
              _this.bind(res)
            }
            // 接收到消息
            if (
              res.push_user_id != undefined ||
              res.push_group_id != undefined
            ) {
              _this.CHANGE_DATA_CHAT({
                type: 'chatMessage',
                chatMessage: res
              })
            }
          };
          // 监听WebSocket连接
          this.ws.onopen = function (e) {
            let token = _this.$getlocalStorage('token')
            if (e.isTrusted) {
              _this.isHeartbeat = true
              _this.ws.send(
                JSON.stringify({
                  type: 'bind',
                  authorization: token
                })
              )
            }
            console.log(`socket连接成功${JSON.stringify(e)}`)
          }
          // 监听WebSocket错误信息
          this.ws.onerror = function (e) {
            if (_this.errorNum < 6) {
              _this.errorNum += 1
              _this.initWs()
            }
            _this.isHeartbeat = false
          }
          this.ws.onclose = function (e) {
            clearInterval(_this.timer)
            // this.timer = null;
            // _this.$message.warning("Chat room link error");
            console.log(
              'websocket 断开: ' + e.code + ' ' + e.reason + ' ' + e.wasClean
            )
            reconnect()
            // 重连
            function reconnect () {
              // lockReconnect加锁，防止onclose、onerror两次重连
              // if (_this.limitConnect > 0) {
              //   _this.limitConnect--;
              _this.timeConnect++
              console.log('第' + _this.timeConnect + '次重连')
              // 进行重连
              setTimeout(function () {
                _this.initWs()
              }, 2000)
              // } else {
              //   console.log("TCP连接已超时");
              // }
            }
          }
          window.GlobalWebSocket = this.ws
        }
      },
      // 绑定客户端 上线client_id绑定user_id
      bind (res) {
        let { user_id, client_id, nick_name, avatar } = res
        this.userInfo = res
        this.CHANGE_DATA_CHAT({
          type: 'userInfo',
          data: [
            {
              type: 'nick_name',
              nick_name
            },
            {
              type: 'avatar',
              avatar
            },
            {
              type: 'client_id',
              client_id
            },
            {
              type: 'user_id',
              user_id
            }
          ],
          verticalMenu: SideBarItems,
          userProfile: profile,
          logo: loader,
          isHeartbeat: true
        })
        // 握手防止掉线
        this.handshake()
      },

      // 心跳
      handshake () {
        this.timer = setInterval(() => {
          if (this.isHeartbeat) {
            this.ws.send(
              JSON.stringify({
                type: 'Heartbeat'
              })
            )
          } else {
            this.isHeartbeat = false
            clearInterval(this.timer)
          }
        }, 90000)
      }
    }
  }
</script>
<style>
  @import url('../assets/css/custom.css');
  @import url('../assets/css/PriceSlider.css');
</style>

<style lang="less" scoped>
  .gradient {
    // background: url("../assets/images/page/800.png");
    /* background-image: linear-gradient(45deg, #fec615, #1e77c3, #51a79a); */
    background-color: #0097d9;
    overflow-y: auto;
    padding-bottom: 20px;
    // transform: rotate(-45deg);
  }
</style>
